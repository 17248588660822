import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Popup from "./components/common/Popup";
import Loading from "./components/common/Loading";
import queryString from "query-string";
import Home from "./components/Home";
import Checkout from "./components/Checkout";
import Pricing from "./components/Pricing";
import Terms from "./components/Terms";
import About from "./components/About";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <Popup {...this.props} />
          <Switch>
            {/* <Route
              path="/ProductDetail/:id"
              render={(props) => (
                <ProductDetail key={props.match.params.id} {...props} />
              )}
            /> */}
            <Route path="/Privacy" component={Privacy}></Route>
            <Route path="/Contact" component={Contact}></Route>
            <Route path="/About" component={About}></Route>
            <Route path="/Terms" component={Terms}></Route>
            <Route path="/Pricing" component={Pricing}></Route>
            <Route path="/Checkout" component={Checkout}></Route>
            <Route path="/" component={Home}></Route>
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    );
  }
}
export default App;
