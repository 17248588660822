import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/terms.scss";

class Terms extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="terms-page">
          <div className="title">
            Flocksy Terms of Use
            <div className="bgc"></div>
          </div>
          <p>
            <b>1.Acceptance of the Terms of Use.</b>
          </p>
          <p>
            (A)These terms of use are entered into by and between You and
            Flocksy LLC. (“Company” or “We” or “Us”). The following terms of
            use, together with the Privacy Policy, the Independent Contractor
            Agreement, and any documents they expressly incorporate by reference
            (collectively, these “Terms of Use”), govern your access to and use
            of www.flocksy.com and all related subdomains, (“Website”), and
            including any content, functionality, and services offered on or
            through the website (together with its related subdomains and social
            media pages, “Flocksy”) (together, the “Services,” defined in
            section 1(B) below), and your access and usage of Services on
            Flocksy, either as a guest (with limited rights), registered
            Customer (“You,” “you,” “user,” or “Customer”) or registered
            Contractor (“You,” “you,” “user,” “contractor”, or “creative”).{" "}
          </p>
          <p>
            (B)Services. Flocksy connects Customers to a professional team of
            designers, writers, and developers (collectively “Creatives” or
            “Contractors”) who will complete most projects in under 24 hours
            unless otherwise informed by Flocksy or the Contractors at the
            initial stages of the Services. We simply act as a “middle man”
            between Customers and the Contractors participating on our Website.
            Contractors offer a variety of services through our Website to
            Customers, including but not limited to graphic design, copywriting,
            and web development. Contractors provide unlimited services for a
            fixed monthly payment. Contractors complete projects based on the
            priorities Customers set for them. There are no guarantees that all
            projects will be completed by Contractors within a single month.
            Flocksy does not perform trademark, service mark, or copyright
            searches or inquiries, or the like, in order to validate the
            propriety or legality of any delivered work. Accordingly, Customers
            are encouraged to perform their own independent searches with regard
            to any submitted work before using it in any way.
          </p>
          <p>
            (C)Please read these Terms of Use carefully before you start to use
            the Services. By using the Services or by clicking to accept or
            agree to the Terms of Use when this option is made available to you
            in setting up a User Account, you accept and agree to be bound and
            abide by these Terms of Use and our Privacy Policy, incorporated
            herein by reference. If you do not agree to these Terms of Use or
            the Privacy Policy, you must not access Flocksy or use the Services.
          </p>
          <p>
            (D)The Services are offered and available to users and guests who
            are 18 years of age or older. By using Flocksy, you represent and
            warrant that you are of legal age to form a binding contract with
            the Company and meet all of the foregoing eligibility requirements.
            If you do not meet all of these requirements, you must not access or
            use the Services.
          </p>
          <p>
            (E)If you are using Flocksy or the Services on behalf of a company,
            entity, or organization, you represent and warrant that you are an
            authorized representative of such company, entity, or organization
            with the authority to bind it to these Terms of Use. The Company may
            issue you additional terms if you are a commercial entity,
            accordingly.
          </p>
          <p>
            <b>2.Changes to the Terms of Use.</b>
          </p>
          <p>
            We may revise and update these Terms of Use from time to time in our
            sole discretion. All changes are effective immediately when we post
            them and apply to all access to and use of the Services. Your
            continued use of Flocksy following the posting of a revised Terms of
            Use means that you accept and agree to the changes. You are to check
            this page from time to time so that you are aware of any changes, as
            they are binding on you. We will use our best efforts to provide
            notice to any registered users or guests about the changes to these
            Terms of Use.
          </p>
          <p>
            <b>3.Accessing Services and Account Security.</b>
          </p>
          <p>
            (A)We reserve the right to withdraw or amend Flocksy and any of the
            Services (including any support services) provided on Flocksy.
            Further, we reserve the right to revise, update, modify, and close
            down any of the Services provided herein. We will not be liable if,
            for any reason, all or any part of Flocksy or Services are
            unavailable at any time or for any period. From time to time, we may
            restrict access to some parts of Flocksy, restrict access to some
            part of the Services, or Flocksy, to users, including registered
            users.
          </p>
          <p>
            (B)You are solely responsible for (i) making all arrangements
            necessary for you to have access to Flocksy, and (ii) ensuring that
            all persons who access Flocksy or Services through your internet
            connection are aware of these Terms of Use and comply with them.
          </p>
          <p>
            (C)To use certain features of Flocksy or Services, you must register
            for an account with Flocksy by registering as a member on Flocksy
            (“User Account”) and providing certain information about yourself as
            prompted by the Flocksy registration form, including your first and
            last name, your date of birth, a valid telephone number, a valid
            email address at which you are able to receive email, a valid postal
            address at which you are able to receive mail, a valid billing
            address, and a valid phone number. On the Company’s behalf, a
            payment processor (e.g. PayPal, Stripe, or Authorize.net) will
            process your payment for Services and you are required to provide
            your applicable credit card number, expiration date, and CVV code.
            Thus, we will not receive or store your credit card information on
            our server. It is your responsibility to ensure that an accurate
            email address is on file with Flocksy. Flocksy is not responsible
            for funds sent to an incorrect account with a payment processor
            because inaccurate information has been provided.
          </p>
          <p>
            (D)You represent and warrant that: (i) all required registration
            information you submit is truthful and accurate, and (ii) you will
            maintain the accuracy of such information. You may not create more
            than one User Account, and you may delete your User Account at any
            time, for any reason, by following the instructions on Flocksy.
            Flocksy may suspend or terminate your User Account for any reason at
            any time in its sole discretion.
          </p>
          <p>
            (E)To access Flocksy or Services, or create a User Account, you may
            be prompted to tie your login credentials from your Social Media
            account, including, but not limited to, Instagram, Facebook,
            Google+, Twitter, Pinterest, LinkedIn, or other social media
            platforms (collectively, “Social Media”), including registration
            details and other information. It is a condition of your use of
            Flocksy that all the information you provide on Flocksy is correct,
            current, and complete. You agree that all information you provide to
            register with Flocksy or otherwise, including but not limited to
            through the use of any interactive features on Flocksy, is governed
            by our Privacy Policy , and you consent to all actions we take with
            respect to your information consistent with our Privacy Policy.
          </p>
          <p>
            (F)When you sign in you must hereby agree to treat such information
            as confidential, and you must not disclose it to any other person or
            entity. You also acknowledge that your User Account is personal to
            you and agree not to provide any other person with access to
            Flocksy, Services, or portions of either your username, password or
            other security information. You agree to notify us immediately of
            any unauthorized access to or use of your username or password or
            any other breach of security. You should use particular caution when
            accessing your account from a public or shared computer or mobile
            device so that others are not able to view or record your password
            or other personal information.{" "}
          </p>
          <p>
            (G)We have the right to disable any username, password or other
            identifier, whether chosen by you or provided by us, at any time in
            our sole discretion for any or no reason, including if, in our
            opinion, you have violated any provision of these Terms of Use.
          </p>
          <p>
            <b>4.Services Transaction Terms.</b>
          </p>
          <p>
            (A)A recurring fee will be charged to use or access our Services.
            Additional charges may be applied as well.
          </p>
          <p>
            (B)All transactions for Services are subject to sales tax and VAT,
            including by way of any jurisdiction in which we are legally
            obligated to collect a sales tax.
          </p>
          <p>
            (C)You are solely responsible for the privacy of the information
            used to login to your User Account and Flocksy is not responsible in
            any way for misuse of your User Account or for payment being sent to
            the wrong person due to illegal or unauthorized access to your User
            Account by third-parties using your login information. Flocksy
            reserves the right to freeze or hold payment on any funds owed to
            your Flocksy Account due to suspicious activity.
          </p>
          <p>
            (D)The Company regularly adjusts prices for Services based on a
            variety of factors. You will receive an email from the Company 14
            days prior to any price increases for the Services. You will also be
            informed that you may cancel your User Account to avoid any such
            increases.
          </p>
          <p>
            (E)Flocksy is not responsible for creating or maintaining backups of
            your (or your client’s) website, files, or server and you are
            responsible for creating and maintaining backups for your websites,
            files, and servers prior to providing Contractors access to them.
            Flocksy does not warrant the functions of your Website will meet
            your expectations of traffic or resulting business following any
            changes or updates performed by Flocksy. In no event will Flocksy be
            liable to you or any third-party for any damages, including any lost
            profits, lost savings, lost information, or other incidental,
            consequential, or special damages arising out of the operation of or
            inability to operate your website or any of its web pages, even if
            Flocksy has been advised of the possibility of such damages.
          </p>
          <p>
            (F)You hereby acknowledge that you understand that Flocksy is an
            intermediary between you and the Contractors and that Flocksy shall
            have no obligation or duty to perform trademark, service mark, or
            copyright searches or inquiries, or the like, in order to validate
            the propriety or legality of any delivered work. Accordingly, you
            are encouraged to perform your own independent searches with regard
            to any submitted work, and related licenses, before using it in any
            way. Furthermore, you acknowledge that Flocksy shall have no
            responsibility or obligation of any kind to assist you in seeking
            state or federal intellectual property protection (i.e., without
            limitation, trademark or copyright registration) for any work, nor
            shall Flocksy be responsible for otherwise assisting you in any way
            in your attempt to perfect your rights in or to any completed work.
            You also agree that Flocksy is in no way responsible for ensuring
            the originality of any creative work delivered, and that it is your
            full responsibility to research all creative work received by you
            before using it in any way. Flocksy is in no way responsible for any
            legal or financial difficulty of any kind incurred by using any
            service purchased through Flocksy.
          </p>
          <p>
            (G)The Company strives to provide current and accurate information
            on Flocksy about the Services or from merchants offering goods and
            services on Flocksy, or regarding the offers and transactions
            available to you. Nevertheless, errors and misprints occur, and
            accordingly, the Company reserves the right to reject, correct,
            cancel, or terminate any transaction for which the price or offer
            details were incorrectly displayed or for which any other erroneous
            or inaccurate information was provided. The Company reserves the
            right to do this at any time during the transaction process,
            including after a transaction has been submitted and even if the
            transaction has been confirmed by us. Under no circumstances is the
            Company obligated to process or complete any transaction based on
            any error, misprint, or otherwise.
          </p>
          <p>
            (H)Legal title into the work product will transfer from Flocksy and
            Contractors to you only if you are current on all Payments due to
            Flocksy and Contractors, subject to the full performance of all
            other obligations by you. Until all payment and performance occur,
            Flocksy and Contractors shall retain all rights and legal title and
            each and every other right, at law or in equity, with respect to the
            work product. In the event of a refund, all rights and legal title
            to the work product the refund is for will be transferred back from
            you to Flocksy. Legal title will be transferred to users in
            accordance with this section, subject to any limitations and
            reservations under these Terms and other agreements between the
            Company, Customers, and Contractors.
          </p>
          <p>The refund rules of the Website are the following:</p>
          <p>
            (I)All transactions for Services are final and non-refundable; once
            a transaction has been submitted, it may not be canceled or reversed
            except with the written consent of the Company. The Company, at all
            times, reserves the right to review, cancel, and reverse any
            transaction in the event of fraud, a charge-back of any transaction
            fee paid by credit card or other payment method accepted by the
            Company, or an error or mistake by the Company. No refunds will be
            available for services that are paid directly to a Contractor.
          </p>
          <p>
            (J)New users are eligible for a refund of the initial payment if
            requested in writing during the first 14 days of opening the User
            Account and the refund option has not been waived by the new user.
            Your User Account automatically renews each month on the calendar
            day that it was created. Any cancellation requests must be made by
            emailing support@flocksy.com, and must be made 24 hours prior to the
            renewal date in order to avoid being charged. User Accounts will be
            considered cancelled only after users receive a written confirmation
            from Flocksy. We reserve the right to refuse any refund.
          </p>
          <p>
            The rules relating to the release of completed work and turnaround
            times are the following:
          </p>
          <p>
            (K)You agree and acknowledge that any work done for hire (includes
            purchase of any premade designs/scripts/files) includes the transfer
            from the Contractor to the user of a royalty-free, irrevocable,
            exclusive worldwide right to use “completed creative material” in
            any way whatsoever, this transfer of rights occurs when payment has
            been made in full to either Flocksy or directly to the Contractor,
            even if the work has not been completed. The Contractor retains the
            rights to display the work in their portfolio and advertising as an
            example of the work they do. In addition to the foregoing, and for
            avoidance of doubt, you grant Flocksy and the Contractor a
            perpetual, royalty-free, irrevocable right to use the “completed
            creative material”, in ways and manners in the sole and absolute
            discretion of Flocksy and the Contractor, to advertise, publicize,
            and promote the business and services of Flocksy and the Contractor.
          </p>
          <p>
            (L)If the deadline for a project is missed, you may request that
            your next billing date is extended by the amount of time missed.
            Alternatively, Flocksy reserves the right to start working on an
            additional project in your queue to make up for the missed deadline.
            Missed deadlines are not grounds for a refund. All turnaround times
            are for weekdays only EST; weekends or US holidays are not included.
          </p>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Terms;
